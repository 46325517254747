import { axios } from './init'

export const isAuthorized = async () => {
    return (await axios.get('/api/auth/isAuthorized')).data;
};

export const login = async (login, password) => {
    return (await axios.post('/api/auth/login', { login, password })).data;
};

export const logout = async () => {
    return (await axios.post('/api/auth/logout')).data;
};