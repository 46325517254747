import { axios, trackProgress } from './init'

export const getSectorsByCircle = async (center, radius, progressCallback) => {
    const config = { onDownloadProgress: trackProgress(progressCallback) };

    const response = await axios.post('/api/sectors/circle', { center: mapCoordinates(center), radius }, config);
    return response.data;
};

export const getSectorsByPolygon = async (coordinates, progressCallback) => {
    const config = { onDownloadProgress: trackProgress(progressCallback) };

    const mapped = coordinates.map(c => mapCoordinates(c));
    const response = await axios.post('/api/sectors/polygon', { coordinates: mapped }, config);
    return response.data;
};

export const getSectorsFromFile = async (file, progressCallback) => {
    const config = { onUploadProgress: trackProgress(progressCallback) }

    const data = new FormData();
    data.append('file', file);

    const response = await axios.post('/api/sectors/fromFile', data, config);
    return response.data;
};

export const mapCoordinates = c => ({ "longitude": c[0], "latitude": c[1] });