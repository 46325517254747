import React, { createContext, useContext, useState } from "react";
import { authApi } from '../api';

const authContext = createContext();

export const ProvideAuth = ({ children }) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

const useProvideAuth = () => {
    const [user, setUser] = useState(undefined);

    const getUser = async () => {
        try {
            await authApi.isAuthorized();
            setUser({});
        }
        catch (error) {
            setUser(null);
        }
    };

    const resetUser = () => {
        setUser(null);
    };

    const signin = async (login, password) => {
        await authApi.login(login, password);
        setUser({});
    };

    const signout = async () => {
        await authApi.logout();
        setUser(null);
    };

    return {
        user,
        getUser,
        resetUser,
        signin,
        signout
    };
}
