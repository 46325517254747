import React from 'react';
import { Button, Nav, Tab } from 'react-bootstrap';
import { PrintAndDistributionPrice } from './PrintAndDistributionPrice';
import { PrintConfiguration } from './PrintConfiguration';

export function ServiceOptions({ price, ...props }) {
    const onSubmit = (type, distribution, printConfiguration) => {
        props.onSubmit?.(type, distribution, printConfiguration);
    };

    return (
        <>
            Select service type:
            <div className="w-100">
                <Tab.Container defaultActiveKey="first">
                    <div className="row w-100">
                        <div className="col-sm-2">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Data Only</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Print only</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Print + Distribution</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="col-sm-10">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <Button onClick={() => onSubmit("DataOnly", true, undefined)}>Apply and calculate price</Button>
                                            </div>
                                            {price && <PrintAndDistributionPrice className="col-sm-4" {...price} />}
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="container">
                                        <div className="row">
                                            <PrintConfiguration className="col-sm-8" onSubmit={printConfiguration => onSubmit("PrintOnly", false, printConfiguration)} />
                                            {price && <PrintAndDistributionPrice className="col-sm-4" {...price} />}
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className="container">
                                        <div className="row">
                                            <PrintConfiguration className="col-sm-8" onSubmit={printConfiguration => onSubmit("PrintAndDistribution", true, printConfiguration)} />
                                            {price && <PrintAndDistributionPrice className="col-sm-4" {...price} />}
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    );
}
