import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

export function SchedulingDate({ ...props }) {
    const [schedulingDateType, setSchedulingDateType] = useState();
    const [schedulingDate, setSchedulingDate] = useState();

    useEffect(() => {
        if (schedulingDate === undefined)
            return;

        props.onChange?.(schedulingDate);
    }, [schedulingDate]);

    const handleSchedulingDateType = e => {
        const schedulingType = e.target.value;
        setSchedulingDateType(schedulingType);
        if (schedulingType === "ASAP") {
            setSchedulingDate("ASAP");
        }
        else {
            setSchedulingDate(null);
        }
    };

    const handleSchedulingDate = e => {
        const value = e.target.value;
        setSchedulingDate(value);
    };

    return (
        <>
            Select delivery date:
            <Form.Check type="radio" name="scheduling" value="ASAP" label="ASAP" onChange={handleSchedulingDateType} />
            <Form.Check type="radio" name="scheduling" value="Date" label="Set a date" onChange={handleSchedulingDateType} />
            {schedulingDateType === "Date" && <Form.Control type="date" onChange={handleSchedulingDate} />}
        </>
    );
}
