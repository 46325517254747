import React, { useEffect, useRef, useState } from 'react';

function fillTreeState(tree) {
    return tree.map(item => fillNodeState(item, item.checked));
}

function fillNodeState(node, checked) {
    const items = (node.items ?? []).map(item => fillNodeState(item, checked));
    return { text: node.text, checked: (checked ?? false), indeterminate: false, items };
}

export function CheckboxesTree(props) {
    const [states, setStates] = useState([]);

    useEffect(() => {
        const states = fillTreeState(props.data);
        setStates(states);
    }, [props.data])

    const onChildUpdate = (state, position) => {
        const updatedStates = states.map((item, index) => index === position ? state : item);
        setStates(updatedStates);
        props.onChange?.(updatedStates);
    }

    return (
        <div>
            {
                states.map((node, index) => {
                    return <CheckboxesTreeNode key={index} {...node} checked={states[index].checked} indeterminate={states[index].indeterminate} onChange={(state) => onChildUpdate(state, index)} />
                })
            }
        </div>
    );
}

export function CheckboxesTreeNode(props) {
    const { text, items = [], checked, indeterminate } = props;
    const input = useRef();

    const calcChildrenStates = checked => items.map(item => fillNodeState(item, checked));
    const [states, setStates] = useState(() => calcChildrenStates(checked));

    const calcState = (states) => {
        let indeterminate = false,
            checked = false;

        const allChildrenChecked = states.every(item => item.checked && !item.indeterminate);
        const allChildrenUnchecked = states.every(item => !item.checked && !item.indeterminate);

        if (allChildrenChecked) {
            checked = true;
        }
        else if (allChildrenUnchecked) {
            checked = false;
        }
        else {
            indeterminate = true;
        }

        return { checked, indeterminate };
    }

    const onUserChange = () => {
        const checked = input.current.checked;
        const updatedStates = calcChildrenStates(checked);
        setStates(updatedStates);

        props.onChange?.({ text, checked, indeterminate: false, items: updatedStates });
    }

    const onChildChange = (state, position) => {
        const updatedStates = states.map((item, index) => index === position ? state : item);
        setStates(updatedStates);

        const { checked, indeterminate } = calcState(updatedStates);
        props.onChange?.({ text, checked, indeterminate, items: updatedStates });
    }

    useEffect(() => {
        input.current.checked = checked;
        input.current.indeterminate = indeterminate;

        if (!indeterminate) {
            const updatedStates = calcChildrenStates(checked);
            setStates(updatedStates);
        }
    }, [checked, indeterminate])

    return (
        <div>
            <div className="text-nowrap">
                <input type="checkbox" value={text} onChange={onUserChange} ref={input} />
                <span> {text}</span>
            </div>
            <div style={{ marginLeft: "2em" }}>
                {
                    items.map((node, index) => {
                        return <CheckboxesTreeNode key={index} {...node} checked={states[index].checked} indeterminate={states[index].indeterminate} onChange={(state) => onChildChange(state, index)} />
                    })
                }
            </div>
        </div>
    );
}