import { axios } from './init';

const endpointsToSkip = [
    '/api/auth/isAuthorized',
    '/api/auth/logout',
].map(v => v.toUpperCase());


const SetupInterceptors = (auth, history) => {
    axios.interceptors.response.use(
        response => response,
        error => {
            const skipCheck = endpointsToSkip.indexOf(error.config.url.toUpperCase()) !== -1;

            if (401 === error.response.status && !skipCheck) {
                history.push('/login', { from: history.location });
                auth.resetUser();
            } else {
                return Promise.reject(error);
            }
        });
}

export default SetupInterceptors;