import React from 'react';
import { Stack } from 'react-bootstrap';
import NavMenu from './NavMenu';

export default function Layout(props) {
    return (
        <>
            <NavMenu />
            <Stack>
                {props.children}
            </Stack>
        </>
    );
}