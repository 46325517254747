import { axios } from './init';

export const calculatePrice = async (sectors, distribution, printConfiguration) => {
    const response = await axios.post('/api/orders/calculatePrice', { sectors, distribution, printConfiguration });
    return response.data;
};

export const submitOrder = async (order) => {
    const response = await axios.post('/api/orders/submit', order);
    return response.data;
};