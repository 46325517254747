import React, { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import styles from './Map.module.css';
import { v4 as uuidv4 } from 'uuid';

export function PrintConfiguration({ onSubmit, onSave, ...props }) {
    const [validated, setValidated] = useState(false);
    const [radioValues, setRadioValues] = useState({});
    const formRef = useRef(null);

    const parseForm = () => {
        const form = formRef.current;

        const paperSize = form["paperSize"].value;
        const paperFormat = form["paperFormat"].value;
        const paperWeight = form["paperWeightSelect"].value;
        const paperFinish = form["paperFinishSelect"].value;
        const paperOrientation = form["orientationSelect"].value;

        return {
            paperSize,
            paperFormat,
            paperWeight,
            paperFinish,
            paperOrientation
        };
    };

    const validateAndGetValue = () => {
        const form = formRef.current;
        if (form.checkValidity() === true) {
            const params = parseForm();
            setValidated(false);
            return params;
        }
        else {
            setValidated(true);
            return null;
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();

        const params = validateAndGetValue();
        if (params) {
            onSubmit(params);
        }
    };

    const onRadioChange = (e, name, value) => {
        Object.keys(radioValues[name]).forEach(key => radioValues[name][key] = false);
        radioValues[name][value] = e.target.checked;
        setRadioValues({ ...radioValues });
    };

    const ImageRadio = ({ name, label, value, src, invalidFeedback }) => {
        radioValues[name] ??= {};
        radioValues[name][value] ??= false;

        const id = uuidv4();
        return (
            <Form.Group controlId={id} className="col flex-column">
                <Form.Check className="h-100">
                    <Form.Check.Input type="radio" name={name} value={value} required className="d-none" onChange={e => onRadioChange(e, name, value)} checked={radioValues[name][value]} />
                    <Form.Check.Label className={"d-flex flex-column justify-content-end h-100 pt-5 pb-3 " + styles.radioLabel} style={{ borderRadius: "1.5rem" }}>
                        <img className="align-self-center" src={src} alt={label} />
                        <div className="h6 mt-3">{label}</div>
                    </Form.Check.Label>
                    <Form.Control.Feedback type="invalid">{invalidFeedback}</Form.Control.Feedback>
                </Form.Check>
            </Form.Group>
            //<>
            //    <input type="radio" name="paperFormat" value="2pp" className="d-none" id="99" />
            //    <label className={"col-md-3 d-flex flex-column h-100 pt-5 pb-3 " + styles.radioLabel} htmlFor="99" style={{ borderRadius: "1.5rem" }}>
            //        <img className="align-self-center" src="double_sided_leaflet.png" alt="Double sided Leaflet" />
            //        <div className="h6 mt-3">Double sided Leaflet</div>
            //    </label>
            //</>
        );
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef} {...props}>
            <h1 className="h3">Select Your Leaflet Format</h1>
            <hr />

            <h5 className="text-dark">Choose a paper size:</h5>
            <div className="container text-center">
                <div className="row align-items-baseline">
                    <ImageRadio name="paperSize" label="A4" value="A4" src="images/A4.png" invalidFeedback="Choose paper size!" />
                    <ImageRadio name="paperSize" label="A5" value="A5" src="images/A5.png" invalidFeedback="Choose paper size!" />
                    <ImageRadio name="paperSize" label="A6" value="A6" src="images/A6.png" invalidFeedback="Choose paper size!" />
                    <ImageRadio name="paperSize" label="DL" value="DL" src="images/DL.png" invalidFeedback="Choose paper size!" />
                </div>
            </div>
            <hr />

            <h5 className="text-dark">Choose your mail format:</h5>
            <div className="container text-center">
                <div className="row align-items-baseline">
                    <ImageRadio name="paperFormat" label="Double sided Leaflet" value="2pp" src="images/double_sided_leaflet.png" invalidFeedback="Choose mail format!" />
                    <ImageRadio name="paperFormat" label="Folded Leaflet" value="4pp" src="images/folder_leaflet.png" invalidFeedback="Choose mail format!" />
                </div>
            </div>
            <hr />

            <div className="container">
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="paperWeightSelect">
                        <Form.Label className="h6">Choose your paper weight:</Form.Label>
                        <Form.Select required>
                            <option value="">Select paper weight</option>
                            <option value="Standard 90GSM">Standard 90GSM</option>
                            <option value="Premium 150GSM">Premium 150GSM</option>
                            <option value="Premium Plus 170GSM">Premium Plus 170GSM</option>
                            <option value="Postcard 300GSM">Postcard 300GSM</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="paperFinishSelect">
                        <Form.Label className="h6">Choose your paper finish:</Form.Label>
                        <Form.Select required>
                            <option value="">Select paper finish</option>
                            <option value="Matt">Matt</option>
                            <option value="Gloss">Gloss</option>
                            <option value="Silk">Silk</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="orientationSelect">
                        <Form.Label className="h6">Choose your paper orientation:</Form.Label>
                        <Form.Select required>
                            <option value="">Select paper orientation</option>
                            <option value="Landscape">Landscape</option>
                            <option value="Portrait">Portrait</option>
                        </Form.Select>
                    </Form.Group>
                </div>
            </div>

            <Button type="submit">Apply and calculate price</Button>
        </Form>
    );
}
