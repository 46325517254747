import React, { useRef, useState } from 'react';
import { Button, ProgressBar, Table } from 'react-bootstrap';
import prettyBytes from 'pretty-bytes';

export function Upload({ multiple = false, ...props }) {
    const fileInput = useRef();
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState();

    const handleFiles = () => {
        setFiles([...fileInput.current.files])
    }

    const upload = () => {
        props.onUpload?.(files, percentCompleted => setUploadProgress(percentCompleted));
    }

    return (
        <>
            <input type="file" multiple={multiple} style={{ display: "none" }} onChange={handleFiles} ref={fileInput} />

            {
                files.length > 0 &&
                <>
                    <Table striped bordered hover style={{ width: "unset", marginTop: "1rem" }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                files.map((file, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{file.name}</td>
                                            <td>{prettyBytes(file.size)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </>
            }
            <Button onClick={() => fileInput.current.click()}>Select file{multiple ? 's' : ''}</Button>
            <Button onClick={upload} disabled={files.length === 0} style={{ marginLeft: "1rem" }}>Upload</Button>

            {uploadProgress !== undefined && <ProgressBar striped now={uploadProgress} label={`${uploadProgress}%`} />}
        </>
    );
}