import React, { useState } from "react";
import { Button, Form } from 'react-bootstrap';
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import styles from './SignInForm.module.css';

export function SignInForm() {
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();

    const [isLoading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const target = event.currentTarget;
        const email = target["email"].value;
        const password = target["password"].value;

        setLoading(true);
        await login(email, password);
        setLoading(false);
    }

    const login = async (email, password) => {
        const { from } = location.state || { from: { pathname: "/" } };
        await auth.signin(email, password);
        history.replace(from);
    }

    return (
        <div className={styles.formCenter}>
            <Form className={styles.form} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isLoading}> {isLoading ? 'Signing in...' : 'Sign In'} </Button>
            </Form>
        </div>
    );
}