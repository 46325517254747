import React, { useState } from 'react';
import { Alert, Button, Nav, ProgressBar, Stack, Tab } from 'react-bootstrap';
import { filesApi, ordersApi } from '../../api';
import Upload from '../../components/Upload';
import { AreaSelection } from './AreaSelection';
import { PrintAndDistributionPrice } from './PrintAndDistributionPrice';
import { SchedulingDate } from './SchedulingDate';
import { ServiceOptions } from './ServiceOptions';

export default function Map() {
    const [sectors, setSectors] = useState([]);
    const [templateFile, setTemplateFile] = useState();
    const [serviceOptionType, setServiceOptionType] = useState();
    const [printConfiguration, setPrintConfiguration] = useState();
    const [price, setPrice] = useState();
    const [loadProgress, setLoadProgress] = useState();
    const [schedulingDate, setSchedulingDate] = useState();
    const [errors, setErrors] = useState([]);

    const onSectorsChange = newSectors => {
        setSectors(newSectors);
    };

    const uploadTemplate = files => {
        setTemplateFile(files[0]);
    };

    const handleSchedulingDate = date => {
        setSchedulingDate(date);
    };

    const printAndDistributionSave = async (type, distribution, printConfiguration) => {
        setServiceOptionType(type);
        setPrintConfiguration(printConfiguration);

        const price = await ordersApi.calculatePrice(sectors, distribution, printConfiguration);
        setPrice(price);
    };

    const submitOrder = async () => {
        const errors = [];
        if (sectors.length === 0) {
            errors.push("Select sectors!");
        }
        if (!serviceOptionType) {
            errors.push("Select service type!");
        }
        if (!schedulingDate) {
            errors.push("Select scheduling date!");
        }

        setErrors(errors);
        if (errors.length > 0)
            return;

        let templateFileId;
        if (templateFile) {
            setLoadProgress(0);
            const templateUploadResult = await filesApi.uploadTemplate(templateFile, percentCompleted => setLoadProgress(percentCompleted));
            templateFileId = templateUploadResult.id;
        }

        const order = {
            sectors,
            templateFileId,
            serviceOptionType,
            printConfiguration,
            schedulingDate,
        };

        await ordersApi.submitOrder(order);
    };

    return (
        <Stack className="h-100">
            <Tab.Container defaultActiveKey="Step1">
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link role="button" eventKey="Step1">Area selection</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link role="button" eventKey="Step2">Upload template</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link role="button" eventKey="Step3">Service type</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link role="button" eventKey="Step4">Scheduling</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link role="button" eventKey="Step5">Submit</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className="h-100">
                    <Tab.Pane eventKey="Step1" className="h-100">
                        <AreaSelection onSectorsChange={onSectorsChange} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Step2">
                        <div>
                            <div>Upload template:</div>
                            <Upload onUpload={uploadTemplate} />
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Step3" key={sectors}>
                        <ServiceOptions price={price} onSubmit={printAndDistributionSave} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Step4">
                        <div className="col-sm-1">
                            <SchedulingDate onChange={handleSchedulingDate} />
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Step5">
                        <div className="container">
                            {errors.length > 0 && errors.map(error => <Alert key={error} variant="danger">{error}</Alert>)}

                            <div className="row">
                                <div className="col-sm-8">
                                    <div>Sectors: {sectors.length}</div>
                                    <div>Service: {serviceOptionType}</div>
                                    <div>Print configuration: {JSON.stringify(printConfiguration)}</div>
                                    <div>Scheduling: {schedulingDate}</div>
                                </div>
                                {price && <PrintAndDistributionPrice className="col-sm-4" {...price} />}
                            </div>
                            <div className="text-center">
                                <Button onClick={submitOrder}>Submit</Button>
                            </div>
                            {loadProgress !== undefined && loadProgress < 100 && <ProgressBar animated now={loadProgress} label={`${loadProgress}%`} />}
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Stack>
    );
}