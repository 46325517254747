import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import SetupInterceptors from './api/setupInterceptors';
import Layout from './components/Layout';
import SignInForm from './components/SignInForm';
import { useAuth } from './hooks/use-auth';
import Map from './pages/Map';

function PrivateRoute({ children, ...rest }) {
    const auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default function App() {
    const auth = useAuth();
    const history = useHistory();

    SetupInterceptors(auth, history);

    useEffect(() => {
        async function fetchData() {
            await auth.getUser();
        }

        if (auth.user === undefined) {
            fetchData();
        }
    });

    return (
        <>
            {auth.user === undefined
                ?
                <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50" />
                :
                <Layout>
                    <Switch>
                        <Route path='/login'>
                            <SignInForm />
                        </Route>
                        <Redirect exact from="/" to="/map" />
                        <PrivateRoute path='/map' >
                            <Map />
                        </PrivateRoute>
                    </Switch>
                </Layout>
            }
        </>
    );
}