import { axios, trackProgress } from './init'

export const uploadFiles = async (files, progressCallback) => {
    const config = { onUploadProgress: trackProgress(progressCallback) }

    const data = new FormData();
    files.forEach(file => data.append('files', file));

    const response = await axios.post('/api/files', data, config);
    return response.data;
};

export const uploadTemplate = async (file, progressCallback) => {
    const config = { onUploadProgress: trackProgress(progressCallback) }

    const data = new FormData();
    data.append('file', file);

    const response = await axios.post('/api/templates/upload', data, config);
    return response.data;
};