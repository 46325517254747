import axios from 'axios';

const trackProgress = progressCallback => {
    return progressEvent => {
        if (progressCallback) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            progressCallback(percentCompleted);
        }
    }
}

export { axios, trackProgress };