import React from 'react';

export function PrintAndDistributionPrice({ print, distribution, ...props }) {
    const total = (print?.totalCostExcludingVat ?? 0) + (distribution?.totalCostExcludingVat ?? 0);
    return (
        <>
            {(print || distribution) &&
                <div {...props}>
                    <div>
                        <h4>
                            <strong>Cost Summary</strong>
                        </h4>
                        {distribution &&
                            <div>
                                <div className="row">
                                    <div className="col-12">
                                        <strong className="sub-title">DISTRIBUTION</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div>
                                            <div>VOLUME</div>
                                            <span>£{distribution?.volume}</span>
                                        </div>
                                    </div>
                                    <div className=" col-sm-4">
                                        <span>Per Item</span>
                                        <div>
                                            <div>COST EX.VAT</div>
                                            <label>£{distribution?.costPerItemExcludingVat}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <span>Total</span>
                                        <div>
                                            <div>COST EX.VAT</div>
                                            <span>£{distribution?.totalCostExcludingVat}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        {print &&
                            <div>
                                <div className="row">
                                    <div className="col-12">
                                        <strong className="sub-title">PRINT</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div>Volume</div>
                                        <span>£{print?.volume}</span>
                                    </div>
                                    <div className="col-sm-4">
                                        <span>Per Item</span>
                                        <div>
                                            <div>COST EX.VAT</div>
                                            <span>£{print?.costPerItemExcludingVat}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <span>Total</span>
                                        <div>
                                            <div>COST EX.VAT</div>
                                            <span>£{print?.totalCostExcludingVat}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-6">
                                <strong>SUB TOTAL</strong>
                            </div>
                            <span>£{total}</span>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
