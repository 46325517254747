import React, { useEffect, useRef, useState } from 'react';
import { Form, Nav, Stack, Tab } from 'react-bootstrap';
import { sectorsApi } from '../../api';
import CheckboxesTree from '../../components/CheckboxesTree';
import OpenLayerMap from '../../components/OpenLayerMap';
import Upload from '../../components/Upload';

export function AreaSelection({ ...props }) {
    const firstRender = useRef(true);
    const [sectors, setSectors] = useState([]);
    const [manualSelection, setManualSelection] = useState([]);
    const [suppression, setSuppression] = useState(false);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        props.onSectorsChange?.(sectors.filter(s => s.selected).map(s => s.sector));
    }, [sectors]);

    const onSectorsChange = newSectors => {
        const manualSelection = newSectors.map(s => ({ text: s.sector.name, checked: s.selected }));

        setSectors(newSectors);
        setManualSelection(manualSelection);
    };

    const onManualChekboxesChange = newManualSelection => {
        const newSectors = [...sectors];
        newManualSelection.forEach((value, index) => newSectors[index].selected = value.checked);

        setSectors(newSectors);
        setManualSelection(newManualSelection);
    };

    const changeSuppressionState = e => {
        setSuppression(e.target.checked);
    }

    const uploadTemplate = async (files, progressCallback) => {
        const suppressionValue = suppression;

        const sectorsFromFile = await sectorsApi.getSectorsFromFile(files[0], progressCallback);
        let newSectors = [...sectors];

        if (suppressionValue) {
            sectorsFromFile.forEach(sector => newSectors = newSectors.filter(s => s.sector.name !== sector.name));
        }
        else {
            sectorsFromFile.forEach(sector => {
                const index = newSectors.findIndex(s => s.sector.name === sector.name);
                if (index > -1) {
                    newSectors[index].selected = true;
                } else {
                    newSectors.push({ sector, selected: true });
                }
            });
        }

        const manualSelection = newSectors.map(s => ({ text: s.sector.name, checked: s.selected }));

        setSectors(newSectors);
        setManualSelection(manualSelection);
    };

    return (
        <Stack className="h-100">
            <Tab.Container defaultActiveKey="Map">
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link role="button" eventKey="Map">Map</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link role="button" eventKey="Manual">Manual</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link role="button" eventKey="Upload">From file</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className="h-100">
                    <Tab.Pane eventKey="Map" className="h-100">
                        <OpenLayerMap className="h-100" onSectorsChange={onSectorsChange} sectors={sectors} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Manual" className="h-100">
                        <div className="h-100 d-flex flex-column">
                            <div className="overflow-scroll" style={{ flex: "1 1 0" }}>
                                <CheckboxesTree data={manualSelection} onChange={onManualChekboxesChange} />
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Upload">
                        <Upload onUpload={uploadTemplate} />
                        <Form.Switch onChange={changeSuppressionState} label="Enable/Disable suppression" />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Stack>
    );
}
